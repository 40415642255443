import React from "react";
import {graphql, data, Link} from "gatsby";

import InternalLayout from "../layouts/internallayout";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";


const Container = styled(Link)`
  &.disabled {
    display: block;
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    z-index: 10;

    @media (min-width: 768px) {
      width: calc(33% - 1em);
      margin: 0 0.5em;
      border: 1px solid #ebebeb;
      margin-bottom: 1em;
    }


    .content {
      position: relative;
      color: #100f26;
      text-align: center;

      h2 {
        margin-bottom: 0.5em;
        font-size: 1.3em;
      }
    }


    .image {
      display: block;
      //position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      width: 100%;
      height: 100%;
      max-height: 200px;
    }

    .cardText {
      padding: 1em;
      width: 100%
    }

    .cardText h2 {

    }


    .cardText .button {
      display: inline-block;
      border-radius: 5px;
      border: 2px solid transparent;
      padding: 1em 2em;
      font-size: 0.8em;
      font-weight: 600;
      color: #fff;
      text-align: center;
      background: #e18a29;
      cursor: pointer;
    }

    .content .copy,
    .close {
      display: none;
    }
  }

  // when the card is a modal
  &.active {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
    background-color: #f8f6f6;
    overflow: auto;

    width: calc(100% - 4rem);
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem 1rem 1rem;

    z-index: 900;
    border: 1px solid #ebebeb;


    .content {
      position: relative;
      color: #100f26;
      text-align: center;

      h2 {
        margin-bottom: 0.5em;
        font-size: 1.3em;
      }
      
      @media(min-width: 768px){
        flex-direction: row;
      }
    }


    .image {
      //display: block;
      //position: relative;
      //
      //width: 100%;
      //height: 100%;
      //max-height: 200px;
      //margin: 0 0 1rem 1rem;

      @media (min-width: 500px) {
        width: 35%;
        max-height: unset;
        float: right;
      }
    }

    .cardText {
      padding: 1em;
      width: 100%
    }

    .button {
      display: none;
    }

    .content .copy {
      display: block;
      text-align: left;
    }

    .close {
      position: fixed;
      z-index: 500;
      //left: 0;
      background-color: #e18a29;
      padding: 0.5rem;
      border-radius: 0 0 5px 5px;
      color: #fff;
      border: transparent 1px solid;
      cursor: pointer;
      
      :hover{
        background-color: #fff;
        padding: 0.5rem;
        border-radius: 0 0 5px 5px;
        color: #e18a29;
        border: 1px solid #e18a29;
        cursor: pointer;
      }
    }
  }

`

class ServiceCard extends React.Component {

    constructor(props) {
        super(props);
        this.addActiveClass = this.addActiveClass.bind(this);
        this.removeActiveClass = this.addActiveClass.bind(this);

        this.state = {
            active: false,
        };
    }
    addActiveClass() {
        const currentState = this.state.active;
        this.setState({
            active: !currentState
        });
    };

    removeActiveClass() {
        const currentState = this.state.active;
        this.setState({
            disabled: !currentState
        });
    };

    render() {
            const service = this.props.service;

            return(
                <Container
                    state={this.state.active}
                    className={this.state.active ? 'active': 'disabled'}
                    // onClick={this.removeActiveClass}
                    to={service.fields.slug}
                >
                    {/*<span onClick={this.addActiveClass} className="close">Close</span>*/}
                        <div className="content">
                            <div className="cardText">
                                <GatsbyImage
                                    // style={serviceImage}
                                    image={service.frontmatter.serviceImage.childImageSharp.gatsbyImageData}
                                    alt={service.frontmatter.title}
                                    class="image"
                                    // objectPosition="50% 1%"
                                />
                                <h2>{service.frontmatter.title}</h2>
                                <button className="button">LEARN MORE</button>
                            </div>
                            <div className="copy" dangerouslySetInnerHTML={{ __html: service.html }} />
                        </div>
                </Container>
            );
        }
    }

    export default ServiceCard;
