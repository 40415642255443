import React from "react";
import {graphql} from "gatsby";
import InternalLayout from "../../layouts/internallayout";
import styled from "styled-components";
import ServiceCard from '../../components/serviceCard'

const Container = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 4em 1em;
    position: relative;
    overflow: hidden;
`

const Services = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a{
    &:hover{
      text-decoration: none;
    }
  }
`


class PhysiotherapyServicesPage extends React.Component {

    // constructor(props) {
    //     super(props);
    //     this.addActiveClass = this.addActiveClass.bind(this);
    //     this.state = {
    //         active: false,
    //     };
    // }
    // addActiveClass() {
    //     const currentState = this.state.active;
    //     this.setState({
    //         active: !currentState
    //     });
    // };

    // constructor(props) {
    //     super(props);
    // }
    //
    // handleClick = (o) => {
    //     console.log('click');
    //     this.classList.add("beautify");
    // };


    render() {
        return (
            <InternalLayout>

                <Container>
                    <h1>Physiotherapy Services</h1>
                    <Services>
                        {this.props.data.physioServices.edges.map(service => {
                            // filter out drafts in production
                            return (
                                <ServiceCard
                                    key={service.node.fields.id}
                                    service={service.node}
                                />
                            );
                        })}
                    </Services>
                </Container>

            </InternalLayout>
        )
    }
}

export default PhysiotherapyServicesPage

export const pageQuery = graphql`
    query {
         physioServices: allMarkdownRemark(
            sort: {fields: frontmatter___tags, order: ASC}
            filter: {frontmatter: {tags: {in: "physiotherapy"}}}
          ) {
            edges {
              node {
                frontmatter {
                  title
                  layout
                  author
                  tags
                  serviceImage {
                    id
                    childImageSharp {
                        gatsbyImageData(
                        quality: 60, 
                        formats: [AUTO, WEBP], 
                        placeholder: BLURRED)
                    }
                    internal {
                      content
                      description
                      ignoreType
                      mediaType
                    }
                  }
                }
                fields {
                  slug
                }
                id
                timeToRead
                html
              }
            }
          }
          supervisedServices: allMarkdownRemark(
            sort: {fields: frontmatter___tags, order: ASC}
            filter: {frontmatter: {tags: {in: "supervised-exercise"}}}
          ) {
            edges {
              node {
                frontmatter {
                  title
                  layout
                  author
                  tags
                  serviceImage {
                    id
                    childImageSharp {
                                    gatsbyImageData(quality: 60, formats: [AUTO, WEBP], placeholder: BLURRED)
                    }
                    internal {
                      content
                      description
                      ignoreType
                      mediaType
                    }
                  }
                }
                fields {
                  slug
                }
                id
                timeToRead
                html
              }
            }
          }
    }
`;